import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col } from "react-bootstrap"
import mapafrica from "../../images/topic-3/section-1/map-africa.jpg"
import mapsouth from "../../images/topic-3/section-1/map-south-africa.jpg"
import elephant from "../../images/topic-3/section-1/elephant-with-tree-1.jpg"
import graph1 from "../../images/topic-3/section-1/early-years.svg"
import graph2 from "../../images/topic-3/section-1/growing-years.svg"
import graph3 from "../../images/topic-3/section-1/middle-years.svg"
import graph4 from "../../images/topic-3/section-1/late-20th-century.svg"
import graph1_fr from "../../images/topic-3/section-1/early-years_fr.svg"
import graph2_fr from "../../images/topic-3/section-1/growing-years_fr.svg"
import graph3_fr from "../../images/topic-3/section-1/middle-years_fr.svg"
import graph4_fr from "../../images/topic-3/section-1/late-20th-century_fr.svg"
import slider1 from "../../images/topic-3/section-1/early-years.jpg"
import slider2 from "../../images/topic-3/section-1/growing-years.jpg"
import slider3 from "../../images/topic-3/section-1/middle-years.jpg"
import slider4 from "../../images/topic-3/section-1/late-20th-century.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"
import BottomNav from "../../components/bottomNav"
import Instructions from "../../components/instructions"
import TopHeader from "../../components/topHeader"
import MyBreadcrumb from "../../components/myBreadcrumb"
import MyModal from "../../components/eModal"
import MyModal2 from "../../components/mapModal"
import parse from "html-react-parser"
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const EPopGrowthSection1 = ({ location }) => {
  const { languages, originalPath, language } = useI18next()
  const { t } = useTranslation()
  // ============================================================================================
  const [part, setPart] = useState(0)
  const [sliderNum, setSliderNum] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [modal2Show, setModal2Show] = useState(false)
  // ============================================================================================
  const pageContent = {
    breadcrumb: [
      parse(t("Topic3_Section1_breadcrumb[1][0]")),
      parse(t("Topic3_Section1_breadcrumb[1][1]")),
      parse(t("Topic3_Section1_breadcrumb[1][2]")),
      parse(t("Topic3_Section1_breadcrumb[1][3]")),
    ],
    title: [
      parse(t("Topic3_Section1_title[0]")),
      parse(t("Topic3_Section1_title[1]")),
      parse(t("Topic3_Section1_title[2]")),
      parse(t("Topic3_Section1_title[3]")),
    ],
    topText: [
      <span>
        {parse(t("Topic3_Section1_topText[0][0]"))}
        <span className="linkMe" onClick={() => setModalShow(true)}>
          {parse(t("Topic3_Section1_topText[0][1]"))}
        </span>
        {parse(t("Topic3_Section1_topText[0][2]"))}
      </span>,
      <span>
        {parse(t("Topic3_Section1_topText[1][0]"))}
        <p>
          <span className="linkMe" onClick={() => setModal2Show(true)}>
            {parse(t("Topic3_Section1_topText[1][1]"))}
          </span>
          {parse(t("Topic3_Section1_topText[1][2]"))}
        </p>
      </span>,
      parse(t("Topic3_Section1_topText[2]")),
      parse(t("Topic3_Section1_topText[3]")),
    ],
    bullets: [
      parse(t("Topic3_Section1_bullets[0]")),
      parse(t("Topic3_Section1_bullets[1]")),
    ],
    bottomText: ["", "", "", parse(t("Topic3_Section1_bottomText[3]"))],
    images: {
      maps: [
        {
          src: mapafrica,
          alt: parse(t("Topic3_Section1_images.maps[0].alt")),
          text: parse(t("Topic3_Section1_images.maps[0].caption")),
        },
        {
          src: mapsouth,
          alt: parse(t("Topic3_Section1_images.maps[1].alt")),
          text: parse(t("Topic3_Section1_images.maps[1].caption")),
        },
        {
          src: elephant,
          alt: parse(t("Topic3_Section1_images.maps[2].alt")),
          text: parse(t("Topic3_Section1_images.maps[2].caption")),
        },
        {
          src: "",
          alt: parse(t("Topic3_Section1_images.maps[3].alt")),
          text: (
            <span>
              {parse(t("Topic3_Section1_images.maps[3].caption[0]"))}
              <a href="https://www.parks-sa.co.za" target="_blank">
                {parse(t("Topic3_Section1_images.maps[3].caption[1]"))}
              </a>
            </span>
          ),
        },
        {
          src: "",
          alt: parse(t("Topic3_Section1_images.maps[4].alt")),
          text: parse(t("Topic3_Section1_images.maps[4].caption")),
        },
      ],
      graphs: [
        { src: language == "en" ? graph1 : graph1_fr, alt: parse(t("Topic3_Section1_images.graphs[0].alt")) },
        { src: language == "en" ? graph2 : graph2_fr, alt: parse(t("Topic3_Section1_images.graphs[1].alt")) },
        { src: language == "en" ? graph3 : graph2_fr, alt: parse(t("Topic3_Section1_images.graphs[2].alt")) },
        { src: language == "en" ? graph4 : graph3_fr, alt: parse(t("Topic3_Section1_images.graphs[3].alt")) },
      ],
      sliders: [
        {
          src: slider1,
          alt: parse(t("Topic3_Section1_images.sliders[0].alt")),
          text: (
            <span>
              <h4>
                {parse(t("Topic3_Section1_images.sliders[0].caption[0]"))}
              </h4>
              <p style={{ fontSize: "16px" }}>
                {parse(t("Topic3_Section1_images.sliders[0].caption[1]"))}
              </p>
            </span>
          ),
        },
        {
          src: slider2,
          alt: parse(t("Topic3_Section1_images.sliders[1].alt")),
          text: (
            <span>
              <h4>
                {parse(t("Topic3_Section1_images.sliders[1].caption[0]"))}
              </h4>
              <p style={{ fontSize: "16px" }}>
                {parse(t("Topic3_Section1_images.sliders[1].caption[1]"))}
              </p>
            </span>
          ),
        },
        {
          src: slider3,
          alt: parse(t("Topic3_Section1_images.sliders[2].alt")),
          text: (
            <span>
              <h4>
                {parse(t("Topic3_Section1_images.sliders[2].caption[0]"))}
              </h4>
              <p style={{ fontSize: "16px" }}>
                {parse(t("Topic3_Section1_images.sliders[2].caption[1]"))}
              </p>
            </span>
          ),
        },
        {
          src: slider4,
          alt: parse(t("Topic3_Section1_images.sliders[3].alt")),
          text: (
            <span>
              <h4>
                {parse(t("Topic3_Section1_images.sliders[3].caption[0]"))}
              </h4>
              <p style={{ fontSize: "16px" }}>
                {parse(t("Topic3_Section1_images.sliders[3].caption[1]"))}
              </p>
            </span>
          ),
        },
      ],
    },
    instructions: [
      parse(t("Topic3_Section1_instructions[0]")),
      parse(t("Topic3_Section1_instructions[1]")),
      parse(t("Topic3_Section1_instructions[2]")),
      parse(t("Topic3_Section1_instructions[3]")),
    ],
  }
  //================================================================================================================
  return (
    <Layout>
      <SEO title={parse(t("Topic3_Section1_pageTitle"))} />
      {/* <MyBreadcrumb topicTitle="Topic 1: Exponential Growth" topicLink="" /> */}

      <MyBreadcrumb
        topicTitle={parse(t("Topic3_Section1_breadcrumb[0]"))}
        topicLink="epopulationgrowth"
        currentTitle={pageContent.breadcrumb[part]}
      />

      <div className="homeContent">
        <Row>
          <Col sm="12" hidden={part != 3}>
            <TopHeader
              title={pageContent.title[part]}
              content={pageContent.topText[part]}
            />
          </Col>
          <Col sm="8" hidden={part == 3}>
            <TopHeader
              title={pageContent.title[part]}
              content={pageContent.topText[part]}
            />
          </Col>
          <Col sm="4" hidden={part == 3}>
            <img
              src={pageContent.images.maps[part].src}
              alt={pageContent.images.maps[part].alt}
              className="roundMe"
              style={{ maxWidth: `290px`, marginBottom: `1.45rem` }}
            />
            <p className="smText">{pageContent.images.maps[part].text}</p>
          </Col>
        </Row>

        {/* part 4============================================== */}
        <div hidden={part != 3}>
          <Row>
            <Col sm="12" md="12">
              <Instructions content={pageContent.instructions} />
            </Col>
          </Row>
          <Row>
            <Col sm="6" md="8">
              <img
                src={pageContent.images.graphs[sliderNum].src}
                alt={pageContent.images.graphs[sliderNum].alt}
                className="roundMe"
                width="100%"
              />
              <p className="smText">{pageContent.images.maps[part].text}</p>
            </Col>
            {/* slider section */}
            <Col sm="6" md="4">
              <div
                className="boxShadow2"
                style={{ height: "553px", position: "relative" }}
              >
                {pageContent.images.sliders[sliderNum].text}
                <img
                  src={pageContent.images.sliders[sliderNum].src}
                  alt={pageContent.images.sliders[sliderNum].alt}
                  className="roundMe"
                />
                {/* <p className="smText">{pageContent.imageText}</p> */}
                <div className="alignRight bottomMe">
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    style={{ marginRight: "30px" }}
                    className={sliderNum != 0 ? "cursorPoint" : "disabled"}
                    onClick={() => {
                      if (sliderNum != 0) {
                        setSliderNum(sliderNum - 1)
                      }
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className={sliderNum != 3 ? "cursorPoint" : "disabled"}
                    onClick={() => {
                      if (sliderNum != 3) {
                        setSliderNum(sliderNum + 1)
                      }
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <br />
          <p>{pageContent.bottomText[part]}</p>
        </div>
        <hr />

        <MyModal
          size="xl"
          className="rModal"
          show={modalShow}
          onHide={() => setModalShow(false)}
          centered
        />
        <MyModal2
          size="lg"
          className="rModal"
          show={modal2Show}
          onHide={() => setModal2Show(false)}
          centered
        />
        {/* =============BOTTOM========================== */}
        <Row>
          <Col sm="12" md="12" className="alignRight">
            <BottomNav
              onClickPrev={() => {
                if (part != 0) {
                  setPart(part - 1)
                  setSliderNum(0)
                }
              }}
              prevName={part == 0 ? parse(t("Overview")) : parse(t("Previous"))}
              btnLinkPrev={part == 0 ? "/epopulationgrowth" : "#myHeader"}
              btnLinkNext={
                part == 3 ? "/epopulationgrowth/section2" : "#myHeader"
              }
              nextName={parse(t("Next"))}
              onClickNext={() => {
                setPart(part + 1)
              }}
            />
          </Col>
        </Row>
      </div>
      <br />
    </Layout>
  )
}

export default EPopGrowthSection1
