import React, { useState } from "react"
import { Modal, Col, Row, Button, Container } from "react-bootstrap"
import map from "../images/topic-3/section-1/map-knp.jpg"
// import TeX from "@matejmazur/react-katex"
import parse from "html-react-parser"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const MapModal = props => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(0)
  // export default function modal(props) {

  const pageContent = {
    title: parse(t("MapModal_title")),
    topText: parse(t("MapModal_topText")),
  }

  // =========================================================================================================
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Using Grid in Modal
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="show-grid">
        <Container>
          <Modal.Header closeButton>
            <h3>{pageContent.title}</h3>
          </Modal.Header>
          <br />

          <Row>
            <Col sm="12">
              {" "}
              <img src={map} className="roundMe" />
              <p className="smText">{parse(t("MapModal_caption"))}</p>
            </Col>
            {/* <Col sm="6">{pageContent.topText}</Col> */}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btnNext" variant="info" onClick={props.onHide}>
          {parse(t("Close"))}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

// example:=======================================
// function App() {
//   const [modalShow, setModalShow] = useState(false)

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch modal with grid
//       </Button>

//       <MydModal show={modalShow} onHide={() => setModalShow(false)} />
//     </>
//   )
// }

export default MapModal
